import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as JsBarcode from 'jsbarcode';
import { PdfRecieptService } from './pdf-reciept.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import * as JSPM from 'jsprintmanager'
// import { PrintDriver } from "ng-thermal-print/lib/drivers/PrintDriver";
// import { PrintService, UsbDriver, WebPrintDriver } from "ng-thermal-print";

@Component({
  selector: 'app-pdf-receipt',
  templateUrl: './pdf-receipt.component.html',
  styleUrls: ['./pdf-receipt.component.scss']
})
export class PdfReceiptComponent implements OnInit {
  livestream_name!: string;
  product_name!: string;
  price!: number;
  host!:string;
  platform!:string;
  quantity!: string;
  order_id!: string;
  time: any;
  printted = false;
  @ViewChild('print', { static: false }) printRef!: ElementRef<any>;
  clientName: any;
  JSPM: any;
  cpj: any;
  // usbPrintDriver!: UsbDriver;
  constructor(private receiptService: PdfRecieptService) {
    let inProcess=false
    this.receiptService.orderObserber.subscribe((res: any) => {
      
      if (res) {
        console.log(res,'res data');
        
        // if(res.livestream_name || res.product || res.quantity || res.orderId || res.client_name){
        //   return
        // }
        if((this.livestream_name == res.livestream_name 
          && this.product_name == res.product 
          && this.price == res.price
          && this.quantity == res.quantity
          && this.clientName == res.client_name)){
            return
          }
        inProcess=true
        this.livestream_name = res.livestream_name || '-'
        this.product_name = res.product || '-'
        this.price = res.price || 0
        this.host = res.host || ''
        this.platform = res.platform || '';
        this.quantity = res.quantity || 0
        this.order_id = res.orderId || '-'
        this.clientName = res.client_name
        this.getDate()
        
        this.breackLineForTime()
      }
    })
  }

  ngOnInit(): void {


  }
  breackLineForTime(){
    try {
      let c :string[]= (this.time as string).split(' ')
    
      let k = c.splice(c.length-2,0,'<br>')
      return c.join(" ")
    } catch (error) {
      return '';
    }
    
  }

  generateBarCode(text: string): String {
    var canvas = document.createElement("canvas");

    JsBarcode(canvas, text, { format: "CODE128" });
    return canvas.toDataURL("image/png");
  }
  getDate() {
    var options: any = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    let d = new Date()
    this.time = d.toLocaleDateString('en-US', options) + " " + d.toLocaleTimeString('en-US');
  }
  printByNgx() {

  }
  printReciept() {
    this.printReciept2()
    return;

    var doc = new jsPDF();
    let elementHTML: HTMLElement | any = window.document.querySelector("#htmlData");
    let _this = this
    doc.html(elementHTML, {
      callback: function (doc2: any) {

        doc2.autoPrint()
        let blob = doc2.output('blob')
        let file = new Blob([blob], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        let win: any = window.open('');
        win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
        win.blur();
        win.opener.focus();
        // setTimeout(() => {
        //   win.close()
        // }, 5000)
      },
      x: 0,
      y: 5,

      width: 140,
      windowWidth: 540

    });
  }
  prevPrint:string='';
  printReciept2() {
    let DATA: HTMLElement | any = window.document.querySelector("#htmlData");
    if(this.prevPrint == DATA.innerText){
      return;
    }

    // return window.print()
    
    this.prevPrint = DATA.innerText
    let _this=this;
    html2canvas(DATA).then(async(canvas: any) => {
      
      // let fileWidth = 208;
      // let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      // let PDF = new jsPDF('portrait','mm',[160,78]);
      // let position = 0;
      // var width = PDF.internal.pageSize.getWidth();
      // var height = PDF.internal.pageSize.getHeight();
      // PDF.addImage(FILEURI, 'PNG', 0, 0,width-5,height-5);
      const pdf = new jsPDF('portrait','mm',[100,78]);
    const imgProps = pdf.getImageProperties(FILEURI);
    const margin = 0.1;
 
    const pdfWidth = pdf.internal.pageSize.width * (1 - margin);
    const pdfHeight = pdf.internal.pageSize.height * (1 - margin);
 
    const x = pdf.internal.pageSize.width * (margin / 2);
    const y = pdf.internal.pageSize.height * (margin / 2);
 
    const widthRatio = pdfWidth / imgProps.width;
    const heightRatio = pdfHeight / imgProps.height;
    const ratio = Math.min(widthRatio, heightRatio);
    
    const w = imgProps.width * ratio;
    const h = imgProps.height * ratio;
 
    pdf.addImage(FILEURI, "JPEG", x, y, w, h);
      pdf.autoPrint()
      let url = (pdf.output('blob'))
      // let win =window
      const mywindow :Window|any= window.open(window.URL.createObjectURL(url),"PRINT")
      mywindow?.blur();
      // win.focus()
      // await _this.wait(5500)
        // mywindow?.close()
        // window.focus();
       
    });

  }
  wait(val: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(true), 50)
    })
  }


getTotal(){
  return this.price * Number(this.quantity);
}

}
